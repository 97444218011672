/* GLOBAL */
html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-grow: 1;
}

/* HEADER */
.dropdown-menu {
  background-color: #1c252c;
}

.dropdown-item,
.dropdown-item:hover {
  color: white;
}

.dropdown-item:hover {
  background-color: #d80615;
}

/* LOGIN */
.bodyContainer {
  height: 100%;
  width: 100%;
  background-color: rgba(28, 37, 44, 255);
}

.form_input {
  padding: 2%;
}

.login_btn {
  padding: 5%;
  align-items: center;
}

.login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form form {
  width: 440px;
  margin-bottom: 15px;
  background: #f7f7f7;
  box-shadow: 40px 40px 40px rgba(0, 0, 0, 0.3);
  padding: 30px;
}

.btn-login {
  min-height: 38px;
  width: 150px;
  background-color: #d80615;
  border-color: #d80615;
  font-size: 15px;
  font-weight: bold;
  margin: 10%;
}
/* LOADING  */
.loadingContainer {
  height: 100%;
  width: 100%;
  /* text-align: center; */
  /* vertical-align: middle; */
}
.loading {
  display: flex;
  margin-top: 5%;
  align-items: center;
  justify-content: center;
}

/* HOME */

.homeContainer {
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
}
.homeContainer h2 {
  text-align: center;
  margin: 2%;
}

.pageContainer {
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
  overflow: auto;
}
.pageContainer h2 {
  text-align: center;
  margin: 2%;
}

.formContainer {
  margin: 2em auto 2em;
  width: 75%;

  background-color: white;
  box-shadow: 4px 10px 10px #1c252c;
  border: 3px solid #1c252c;

  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.formContainer form {
  width: 100%;
  padding: 1%;
  display: flex;
  flex-direction: column;
}
/* .formGroupRow {
  margin: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.formGroupRow label {
  flex: 1;
}
.formGroupRow input {
  flex: 6;
} */

.formContainer form button {
  width: auto;
  margin: auto;
}

.btn-create-form {
  background-color: #d80615;
  border-color: #d80615;
  outline-color: #1c252c;
  box-shadow: none;
}
.btn-create-form:active {
  outline: none;
  box-shadow: none;
}
.btn-create-form:hover,
.btn-create-form:focus {
  background-color: #1c252c;
  border-color: #1c252c;
}

/* ADMIN HOME TABLE*/
.styled-table {
  /* table-layout: fixed; */
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  /* min-width: 400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 0%;
}
.table-responsive {
  margin-left: 5%;
  margin-right: 5%;
}

.styled-table thead tr {
  background-color: #1c252c;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  display: table-cell;
  padding: 1, 10;
  text-align: left;
}

.clickable:hover {
  cursor: pointer;
  background-color: rgb(245, 182, 182) !important;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #d806140e;
}

.styled-table tbody tr {
  border-bottom: 1px solid #1c252c;
}
.styled-table thead th {
  border: 2px solid #1c252c;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #1c252c;
}

/* profile form */
.formHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.formHeader img:hover {
  cursor: pointer;
}

/* Asignar Proceso*/
.filaFechas {
  margin-right: 15%;
}
.selectDay {
  flex: 1;
}
.selectMonth {
  flex: 2;
}
.selectYear {
  flex: 1;
}

.form-check-input:checked {
  background-color: red;
  border-color: red;
}
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border: 1px solid red;
  box-shadow: 0 0 10px red;
}
.formContainerUpload {
  margin: 2em auto 2em;
  width: 75%;

  background-color: white;
  box-shadow: 4px 10px 10px #1c252c;
  border: 3px solid #1c252c;

  padding: 1%;
}
.headerDoc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid black;
  flex-basis: content;
}
.documentos {
  flex: 1 1 auto;
  position: relative;
  text-align: center;
  line-height: 40px;
}
.documentos + .documentos {
  border-left: solid 1px black;
}

.buttonsDocuments {
  display: flex;
  justify-content: space-around;
}

.uploadFiles {
  margin: 4% 10%;
  padding: 3%;
  border: 1px solid black;
}
.uploadFilesComment {
  margin: 4% 10%;
  padding: 1%;
  border-top: 1px solid rgba(0, 0, 0, 0.281);
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}
.uploadFilesComment p {
  margin-bottom: 0%;
}

.TableContainer {
  margin: 2%;
}

.backButton {
  display: flex;
  justify-content: flex-end;
}

.backButtonRevDoc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.buttonsrevsiarDoc {
  display: flex;
  margin: 10px;
  justify-content: space-around;
}

.revDocRadio {
  border-top: 1px solid #1c252c3a;
  /* border-bottom: 2px solid #1c252c3a; */
  margin-bottom: 10px;
}

.vendorForm h5 {
  border-bottom: 1px solid #1c252c3a;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.vendorForm input {
  margin-bottom: 5px;
}
.formDatosRep {
  width: 49%;
}
.datServicioMidWidth {
  width: 30%;
}

.textGDPR {
  display: flex;
  flex: 8;
  margin-bottom: 20px;
}

.noProcesso h5 {
  text-align: center;
}
.separador {
  border-top: 1px solid #1c252c3a;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 30px;
}
.separador h4 {
  text-align: center;
}

.colComentarioNA {
  display: flex;
  flex: 8;
}

.inputPlantilla {
  margin-top: 20px;
  margin-bottom: 20px;
}

.FilterProcess {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.sendMailtoVendorButton {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modifyFecProxRenov {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  display: flex;
}
.TableContainer h5 {
  margin-left: 5%;
  margin-right: 5%;
}
.fecProxRenov {
  margin-right: 1%;
  width: 250px;
}
